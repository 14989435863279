import React from 'react';
import Layout from '../layout/layout';
import Seo from '../layout/seo';

class Help extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo lang="de" title="Hilfe" location={this.props.location} />
      </Layout>
    )
  }
}

export default Help;